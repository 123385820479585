/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots.sso || ($protobuf.roots.sso = {});

$root.IdpAdminService = (function() {

    /**
     * Constructs a new IdpAdminService service.
     * @exports IdpAdminService
     * @classdesc Represents an IdpAdminService
     * @extends $protobuf.rpc.Service
     * @constructor
     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
     */
    function IdpAdminService(rpcImpl, requestDelimited, responseDelimited) {
        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
    }

    (IdpAdminService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = IdpAdminService;

    /**
     * Callback as used by {@link IdpAdminService#list}.
     * @memberof IdpAdminService
     * @typedef listCallback
     * @type {function}
     * @param {Error|null} error Error, if any
     * @param {ListIdentityProviders} [response] ListIdentityProviders
     */

    /**
     * Calls list.
     * @function list
     * @memberof IdpAdminService
     * @instance
     * @param {Empty} request Empty message or plain object
     * @param {IdpAdminService.listCallback} callback Node-style callback called with the error, if any, and ListIdentityProviders
     * @returns {undefined}
     * @variation 1
     */
    Object.defineProperty(IdpAdminService.prototype.list = function list(request, callback) {
        return this.rpcCall(list, $root.Empty, $root.ListIdentityProviders, request, callback);
    }, "name", { value: "list" });

    /**
     * Calls list.
     * @function list
     * @memberof IdpAdminService
     * @instance
     * @param {Empty} request Empty message or plain object
     * @returns {Promise<ListIdentityProviders>} Promise
     * @variation 2
     */

    /**
     * Callback as used by {@link IdpAdminService#create}.
     * @memberof IdpAdminService
     * @typedef createCallback
     * @type {function}
     * @param {Error|null} error Error, if any
     * @param {Empty} [response] Empty
     */

    /**
     * Calls create.
     * @function create
     * @memberof IdpAdminService
     * @instance
     * @param {IdentityProvider} request IdentityProvider message or plain object
     * @param {IdpAdminService.createCallback} callback Node-style callback called with the error, if any, and Empty
     * @returns {undefined}
     * @variation 1
     */
    Object.defineProperty(IdpAdminService.prototype.create = function create(request, callback) {
        return this.rpcCall(create, $root.IdentityProvider, $root.Empty, request, callback);
    }, "name", { value: "create" });

    /**
     * Calls create.
     * @function create
     * @memberof IdpAdminService
     * @instance
     * @param {IdentityProvider} request IdentityProvider message or plain object
     * @returns {Promise<Empty>} Promise
     * @variation 2
     */

    /**
     * Callback as used by {@link IdpAdminService#update}.
     * @memberof IdpAdminService
     * @typedef updateCallback
     * @type {function}
     * @param {Error|null} error Error, if any
     * @param {Empty} [response] Empty
     */

    /**
     * Calls update.
     * @function update
     * @memberof IdpAdminService
     * @instance
     * @param {IdentityProvider} request IdentityProvider message or plain object
     * @param {IdpAdminService.updateCallback} callback Node-style callback called with the error, if any, and Empty
     * @returns {undefined}
     * @variation 1
     */
    Object.defineProperty(IdpAdminService.prototype.update = function update(request, callback) {
        return this.rpcCall(update, $root.IdentityProvider, $root.Empty, request, callback);
    }, "name", { value: "update" });

    /**
     * Calls update.
     * @function update
     * @memberof IdpAdminService
     * @instance
     * @param {IdentityProvider} request IdentityProvider message or plain object
     * @returns {Promise<Empty>} Promise
     * @variation 2
     */

    return IdpAdminService;
})();

$root.SsoAdminService = (function() {

    /**
     * Constructs a new SsoAdminService service.
     * @exports SsoAdminService
     * @classdesc Represents a SsoAdminService
     * @extends $protobuf.rpc.Service
     * @constructor
     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
     */
    function SsoAdminService(rpcImpl, requestDelimited, responseDelimited) {
        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
    }

    (SsoAdminService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SsoAdminService;

    /**
     * Callback as used by {@link SsoAdminService#getConfig}.
     * @memberof SsoAdminService
     * @typedef getConfigCallback
     * @type {function}
     * @param {Error|null} error Error, if any
     * @param {SsoConfig} [response] SsoConfig
     */

    /**
     * Calls getConfig.
     * @function getConfig
     * @memberof SsoAdminService
     * @instance
     * @param {Empty} request Empty message or plain object
     * @param {SsoAdminService.getConfigCallback} callback Node-style callback called with the error, if any, and SsoConfig
     * @returns {undefined}
     * @variation 1
     */
    Object.defineProperty(SsoAdminService.prototype.getConfig = function getConfig(request, callback) {
        return this.rpcCall(getConfig, $root.Empty, $root.SsoConfig, request, callback);
    }, "name", { value: "getConfig" });

    /**
     * Calls getConfig.
     * @function getConfig
     * @memberof SsoAdminService
     * @instance
     * @param {Empty} request Empty message or plain object
     * @returns {Promise<SsoConfig>} Promise
     * @variation 2
     */

    /**
     * Callback as used by {@link SsoAdminService#setConfig}.
     * @memberof SsoAdminService
     * @typedef setConfigCallback
     * @type {function}
     * @param {Error|null} error Error, if any
     * @param {Empty} [response] Empty
     */

    /**
     * Calls setConfig.
     * @function setConfig
     * @memberof SsoAdminService
     * @instance
     * @param {SelectedSsoMode} request SelectedSsoMode message or plain object
     * @param {SsoAdminService.setConfigCallback} callback Node-style callback called with the error, if any, and Empty
     * @returns {undefined}
     * @variation 1
     */
    Object.defineProperty(SsoAdminService.prototype.setConfig = function setConfig(request, callback) {
        return this.rpcCall(setConfig, $root.SelectedSsoMode, $root.Empty, request, callback);
    }, "name", { value: "setConfig" });

    /**
     * Calls setConfig.
     * @function setConfig
     * @memberof SsoAdminService
     * @instance
     * @param {SelectedSsoMode} request SelectedSsoMode message or plain object
     * @returns {Promise<Empty>} Promise
     * @variation 2
     */

    return SsoAdminService;
})();

$root.IdentityProvider = (function() {

    /**
     * Properties of an IdentityProvider.
     * @exports IIdentityProvider
     * @interface IIdentityProvider
     * @property {string|null} [configId] IdentityProvider configId
     * @property {string|null} [entityId] IdentityProvider entityId
     * @property {string|null} [loginUrl] IdentityProvider loginUrl
     * @property {string|null} [certificate] IdentityProvider certificate
     * @property {boolean|null} [isValidated] IdentityProvider isValidated
     * @property {string|null} [name] IdentityProvider name
     */

    /**
     * Constructs a new IdentityProvider.
     * @exports IdentityProvider
     * @classdesc Represents an IdentityProvider.
     * @implements IIdentityProvider
     * @constructor
     * @param {IIdentityProvider=} [p] Properties to set
     */
    function IdentityProvider(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * IdentityProvider configId.
     * @member {string} configId
     * @memberof IdentityProvider
     * @instance
     */
    IdentityProvider.prototype.configId = "";

    /**
     * IdentityProvider entityId.
     * @member {string} entityId
     * @memberof IdentityProvider
     * @instance
     */
    IdentityProvider.prototype.entityId = "";

    /**
     * IdentityProvider loginUrl.
     * @member {string} loginUrl
     * @memberof IdentityProvider
     * @instance
     */
    IdentityProvider.prototype.loginUrl = "";

    /**
     * IdentityProvider certificate.
     * @member {string} certificate
     * @memberof IdentityProvider
     * @instance
     */
    IdentityProvider.prototype.certificate = "";

    /**
     * IdentityProvider isValidated.
     * @member {boolean} isValidated
     * @memberof IdentityProvider
     * @instance
     */
    IdentityProvider.prototype.isValidated = false;

    /**
     * IdentityProvider name.
     * @member {string} name
     * @memberof IdentityProvider
     * @instance
     */
    IdentityProvider.prototype.name = "";

    /**
     * Encodes the specified IdentityProvider message. Does not implicitly {@link IdentityProvider.verify|verify} messages.
     * @function encode
     * @memberof IdentityProvider
     * @static
     * @param {IdentityProvider} m IdentityProvider message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IdentityProvider.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.configId != null && Object.hasOwnProperty.call(m, "configId"))
            w.uint32(10).string(m.configId);
        if (m.entityId != null && Object.hasOwnProperty.call(m, "entityId"))
            w.uint32(18).string(m.entityId);
        if (m.loginUrl != null && Object.hasOwnProperty.call(m, "loginUrl"))
            w.uint32(26).string(m.loginUrl);
        if (m.certificate != null && Object.hasOwnProperty.call(m, "certificate"))
            w.uint32(34).string(m.certificate);
        if (m.isValidated != null && Object.hasOwnProperty.call(m, "isValidated"))
            w.uint32(40).bool(m.isValidated);
        if (m.name != null && Object.hasOwnProperty.call(m, "name"))
            w.uint32(50).string(m.name);
        return w;
    };

    /**
     * Decodes an IdentityProvider message from the specified reader or buffer.
     * @function decode
     * @memberof IdentityProvider
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {IdentityProvider} IdentityProvider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IdentityProvider.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.IdentityProvider();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1:
                m.configId = r.string();
                break;
            case 2:
                m.entityId = r.string();
                break;
            case 3:
                m.loginUrl = r.string();
                break;
            case 4:
                m.certificate = r.string();
                break;
            case 5:
                m.isValidated = r.bool();
                break;
            case 6:
                m.name = r.string();
                break;
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies an IdentityProvider message.
     * @function verify
     * @memberof IdentityProvider
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IdentityProvider.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.configId != null && m.hasOwnProperty("configId")) {
            if (!$util.isString(m.configId))
                return "configId: string expected";
        }
        if (m.entityId != null && m.hasOwnProperty("entityId")) {
            if (!$util.isString(m.entityId))
                return "entityId: string expected";
        }
        if (m.loginUrl != null && m.hasOwnProperty("loginUrl")) {
            if (!$util.isString(m.loginUrl))
                return "loginUrl: string expected";
        }
        if (m.certificate != null && m.hasOwnProperty("certificate")) {
            if (!$util.isString(m.certificate))
                return "certificate: string expected";
        }
        if (m.isValidated != null && m.hasOwnProperty("isValidated")) {
            if (typeof m.isValidated !== "boolean")
                return "isValidated: boolean expected";
        }
        if (m.name != null && m.hasOwnProperty("name")) {
            if (!$util.isString(m.name))
                return "name: string expected";
        }
        return null;
    };

    /**
     * Creates an IdentityProvider message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IdentityProvider
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {IdentityProvider} IdentityProvider
     */
    IdentityProvider.fromObject = function fromObject(d) {
        if (d instanceof $root.IdentityProvider)
            return d;
        var m = new $root.IdentityProvider();
        if (d.configId != null) {
            m.configId = String(d.configId);
        }
        if (d.entityId != null) {
            m.entityId = String(d.entityId);
        }
        if (d.loginUrl != null) {
            m.loginUrl = String(d.loginUrl);
        }
        if (d.certificate != null) {
            m.certificate = String(d.certificate);
        }
        if (d.isValidated != null) {
            m.isValidated = Boolean(d.isValidated);
        }
        if (d.name != null) {
            m.name = String(d.name);
        }
        return m;
    };

    /**
     * Creates a plain object from an IdentityProvider message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IdentityProvider
     * @static
     * @param {IdentityProvider} m IdentityProvider
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IdentityProvider.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.configId = "";
            d.entityId = "";
            d.loginUrl = "";
            d.certificate = "";
            d.isValidated = false;
            d.name = "";
        }
        if (m.configId != null && m.hasOwnProperty("configId")) {
            d.configId = m.configId;
        }
        if (m.entityId != null && m.hasOwnProperty("entityId")) {
            d.entityId = m.entityId;
        }
        if (m.loginUrl != null && m.hasOwnProperty("loginUrl")) {
            d.loginUrl = m.loginUrl;
        }
        if (m.certificate != null && m.hasOwnProperty("certificate")) {
            d.certificate = m.certificate;
        }
        if (m.isValidated != null && m.hasOwnProperty("isValidated")) {
            d.isValidated = m.isValidated;
        }
        if (m.name != null && m.hasOwnProperty("name")) {
            d.name = m.name;
        }
        return d;
    };

    /**
     * Converts this IdentityProvider to JSON.
     * @function toJSON
     * @memberof IdentityProvider
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IdentityProvider.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IdentityProvider;
})();

$root.ListIdentityProviders = (function() {

    /**
     * Properties of a ListIdentityProviders.
     * @exports IListIdentityProviders
     * @interface IListIdentityProviders
     * @property {Array.<IdentityProvider>|null} [providers] ListIdentityProviders providers
     */

    /**
     * Constructs a new ListIdentityProviders.
     * @exports ListIdentityProviders
     * @classdesc Represents a ListIdentityProviders.
     * @implements IListIdentityProviders
     * @constructor
     * @param {IListIdentityProviders=} [p] Properties to set
     */
    function ListIdentityProviders(p) {
        this.providers = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * ListIdentityProviders providers.
     * @member {Array.<IdentityProvider>} providers
     * @memberof ListIdentityProviders
     * @instance
     */
    ListIdentityProviders.prototype.providers = $util.emptyArray;

    /**
     * Encodes the specified ListIdentityProviders message. Does not implicitly {@link ListIdentityProviders.verify|verify} messages.
     * @function encode
     * @memberof ListIdentityProviders
     * @static
     * @param {ListIdentityProviders} m ListIdentityProviders message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ListIdentityProviders.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.providers != null && m.providers.length) {
            for (var i = 0; i < m.providers.length; ++i)
                $root.IdentityProvider.encode(m.providers[i], w.uint32(10).fork()).ldelim();
        }
        return w;
    };

    /**
     * Decodes a ListIdentityProviders message from the specified reader or buffer.
     * @function decode
     * @memberof ListIdentityProviders
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {ListIdentityProviders} ListIdentityProviders
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ListIdentityProviders.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.ListIdentityProviders();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1:
                if (!(m.providers && m.providers.length))
                    m.providers = [];
                m.providers.push($root.IdentityProvider.decode(r, r.uint32()));
                break;
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a ListIdentityProviders message.
     * @function verify
     * @memberof ListIdentityProviders
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ListIdentityProviders.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.providers != null && m.hasOwnProperty("providers")) {
            if (!Array.isArray(m.providers))
                return "providers: array expected";
            for (var i = 0; i < m.providers.length; ++i) {
                {
                    var e = $root.IdentityProvider.verify(m.providers[i]);
                    if (e)
                        return "providers." + e;
                }
            }
        }
        return null;
    };

    /**
     * Creates a ListIdentityProviders message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ListIdentityProviders
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {ListIdentityProviders} ListIdentityProviders
     */
    ListIdentityProviders.fromObject = function fromObject(d) {
        if (d instanceof $root.ListIdentityProviders)
            return d;
        var m = new $root.ListIdentityProviders();
        if (d.providers) {
            if (!Array.isArray(d.providers))
                throw TypeError(".ListIdentityProviders.providers: array expected");
            m.providers = [];
            for (var i = 0; i < d.providers.length; ++i) {
                if (typeof d.providers[i] !== "object")
                    throw TypeError(".ListIdentityProviders.providers: object expected");
                m.providers[i] = $root.IdentityProvider.fromObject(d.providers[i]);
            }
        }
        return m;
    };

    /**
     * Creates a plain object from a ListIdentityProviders message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ListIdentityProviders
     * @static
     * @param {ListIdentityProviders} m ListIdentityProviders
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ListIdentityProviders.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.providers = [];
        }
        if (m.providers && m.providers.length) {
            d.providers = [];
            for (var j = 0; j < m.providers.length; ++j) {
                d.providers[j] = $root.IdentityProvider.toObject(m.providers[j], o);
            }
        }
        return d;
    };

    /**
     * Converts this ListIdentityProviders to JSON.
     * @function toJSON
     * @memberof ListIdentityProviders
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ListIdentityProviders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ListIdentityProviders;
})();

$root.SsoConfig = (function() {

    /**
     * Properties of a SsoConfig.
     * @exports ISsoConfig
     * @interface ISsoConfig
     * @property {boolean|null} [hasConfiguredSso] SsoConfig hasConfiguredSso
     * @property {SelectedSsoMode.SsoMode|null} [mode] SsoConfig mode
     * @property {string|null} [idp] SsoConfig idp
     * @property {number|Long|null} [companyId] SsoConfig companyId
     * @property {string|null} [companyName] SsoConfig companyName
     * @property {number|Long|null} [topLevelCompanyId] SsoConfig topLevelCompanyId
     */

    /**
     * Constructs a new SsoConfig.
     * @exports SsoConfig
     * @classdesc Represents a SsoConfig.
     * @implements ISsoConfig
     * @constructor
     * @param {ISsoConfig=} [p] Properties to set
     */
    function SsoConfig(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * SsoConfig hasConfiguredSso.
     * @member {boolean} hasConfiguredSso
     * @memberof SsoConfig
     * @instance
     */
    SsoConfig.prototype.hasConfiguredSso = false;

    /**
     * SsoConfig mode.
     * @member {SelectedSsoMode.SsoMode} mode
     * @memberof SsoConfig
     * @instance
     */
    SsoConfig.prototype.mode = 0;

    /**
     * SsoConfig idp.
     * @member {string} idp
     * @memberof SsoConfig
     * @instance
     */
    SsoConfig.prototype.idp = "";

    /**
     * SsoConfig companyId.
     * @member {number|Long} companyId
     * @memberof SsoConfig
     * @instance
     */
    SsoConfig.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * SsoConfig companyName.
     * @member {string} companyName
     * @memberof SsoConfig
     * @instance
     */
    SsoConfig.prototype.companyName = "";

    /**
     * SsoConfig topLevelCompanyId.
     * @member {number|Long} topLevelCompanyId
     * @memberof SsoConfig
     * @instance
     */
    SsoConfig.prototype.topLevelCompanyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Encodes the specified SsoConfig message. Does not implicitly {@link SsoConfig.verify|verify} messages.
     * @function encode
     * @memberof SsoConfig
     * @static
     * @param {SsoConfig} m SsoConfig message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SsoConfig.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.hasConfiguredSso != null && Object.hasOwnProperty.call(m, "hasConfiguredSso"))
            w.uint32(8).bool(m.hasConfiguredSso);
        if (m.mode != null && Object.hasOwnProperty.call(m, "mode"))
            w.uint32(16).int32(m.mode);
        if (m.idp != null && Object.hasOwnProperty.call(m, "idp"))
            w.uint32(26).string(m.idp);
        if (m.companyId != null && Object.hasOwnProperty.call(m, "companyId"))
            w.uint32(32).int64(m.companyId);
        if (m.companyName != null && Object.hasOwnProperty.call(m, "companyName"))
            w.uint32(42).string(m.companyName);
        if (m.topLevelCompanyId != null && Object.hasOwnProperty.call(m, "topLevelCompanyId"))
            w.uint32(48).int64(m.topLevelCompanyId);
        return w;
    };

    /**
     * Decodes a SsoConfig message from the specified reader or buffer.
     * @function decode
     * @memberof SsoConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {SsoConfig} SsoConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SsoConfig.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.SsoConfig();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1:
                m.hasConfiguredSso = r.bool();
                break;
            case 2:
                m.mode = r.int32();
                break;
            case 3:
                m.idp = r.string();
                break;
            case 4:
                m.companyId = r.int64();
                break;
            case 5:
                m.companyName = r.string();
                break;
            case 6:
                m.topLevelCompanyId = r.int64();
                break;
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a SsoConfig message.
     * @function verify
     * @memberof SsoConfig
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SsoConfig.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.hasConfiguredSso != null && m.hasOwnProperty("hasConfiguredSso")) {
            if (typeof m.hasConfiguredSso !== "boolean")
                return "hasConfiguredSso: boolean expected";
        }
        if (m.mode != null && m.hasOwnProperty("mode")) {
            switch (m.mode) {
            default:
                return "mode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        }
        if (m.idp != null && m.hasOwnProperty("idp")) {
            if (!$util.isString(m.idp))
                return "idp: string expected";
        }
        if (m.companyId != null && m.hasOwnProperty("companyId")) {
            if (!$util.isInteger(m.companyId) && !(m.companyId && $util.isInteger(m.companyId.low) && $util.isInteger(m.companyId.high)))
                return "companyId: integer|Long expected";
        }
        if (m.companyName != null && m.hasOwnProperty("companyName")) {
            if (!$util.isString(m.companyName))
                return "companyName: string expected";
        }
        if (m.topLevelCompanyId != null && m.hasOwnProperty("topLevelCompanyId")) {
            if (!$util.isInteger(m.topLevelCompanyId) && !(m.topLevelCompanyId && $util.isInteger(m.topLevelCompanyId.low) && $util.isInteger(m.topLevelCompanyId.high)))
                return "topLevelCompanyId: integer|Long expected";
        }
        return null;
    };

    /**
     * Creates a SsoConfig message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SsoConfig
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {SsoConfig} SsoConfig
     */
    SsoConfig.fromObject = function fromObject(d) {
        if (d instanceof $root.SsoConfig)
            return d;
        var m = new $root.SsoConfig();
        if (d.hasConfiguredSso != null) {
            m.hasConfiguredSso = Boolean(d.hasConfiguredSso);
        }
        switch (d.mode) {
        case "LEANPLUM_AND_SSO":
        case 0:
            m.mode = 0;
            break;
        case "SSO_ONLY":
        case 1:
            m.mode = 1;
            break;
        case "SSO_WITH_EXCEPTIONS":
        case 2:
            m.mode = 2;
            break;
        }
        if (d.idp != null) {
            m.idp = String(d.idp);
        }
        if (d.companyId != null) {
            if ($util.Long)
                (m.companyId = $util.Long.fromValue(d.companyId)).unsigned = false;
            else if (typeof d.companyId === "string")
                m.companyId = parseInt(d.companyId, 10);
            else if (typeof d.companyId === "number")
                m.companyId = d.companyId;
            else if (typeof d.companyId === "object")
                m.companyId = new $util.LongBits(d.companyId.low >>> 0, d.companyId.high >>> 0).toNumber();
        }
        if (d.companyName != null) {
            m.companyName = String(d.companyName);
        }
        if (d.topLevelCompanyId != null) {
            if ($util.Long)
                (m.topLevelCompanyId = $util.Long.fromValue(d.topLevelCompanyId)).unsigned = false;
            else if (typeof d.topLevelCompanyId === "string")
                m.topLevelCompanyId = parseInt(d.topLevelCompanyId, 10);
            else if (typeof d.topLevelCompanyId === "number")
                m.topLevelCompanyId = d.topLevelCompanyId;
            else if (typeof d.topLevelCompanyId === "object")
                m.topLevelCompanyId = new $util.LongBits(d.topLevelCompanyId.low >>> 0, d.topLevelCompanyId.high >>> 0).toNumber();
        }
        return m;
    };

    /**
     * Creates a plain object from a SsoConfig message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SsoConfig
     * @static
     * @param {SsoConfig} m SsoConfig
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SsoConfig.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.hasConfiguredSso = false;
            d.mode = o.enums === String ? "LEANPLUM_AND_SSO" : 0;
            d.idp = "";
            if ($util.Long) {
                var n = new $util.Long(0, 0, false);
                d.companyId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
            } else
                d.companyId = o.longs === String ? "0" : 0;
            d.companyName = "";
            if ($util.Long) {
                var n = new $util.Long(0, 0, false);
                d.topLevelCompanyId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
            } else
                d.topLevelCompanyId = o.longs === String ? "0" : 0;
        }
        if (m.hasConfiguredSso != null && m.hasOwnProperty("hasConfiguredSso")) {
            d.hasConfiguredSso = m.hasConfiguredSso;
        }
        if (m.mode != null && m.hasOwnProperty("mode")) {
            d.mode = o.enums === String ? $root.SelectedSsoMode.SsoMode[m.mode] : m.mode;
        }
        if (m.idp != null && m.hasOwnProperty("idp")) {
            d.idp = m.idp;
        }
        if (m.companyId != null && m.hasOwnProperty("companyId")) {
            if (typeof m.companyId === "number")
                d.companyId = o.longs === String ? String(m.companyId) : m.companyId;
            else
                d.companyId = o.longs === String ? $util.Long.prototype.toString.call(m.companyId) : o.longs === Number ? new $util.LongBits(m.companyId.low >>> 0, m.companyId.high >>> 0).toNumber() : m.companyId;
        }
        if (m.companyName != null && m.hasOwnProperty("companyName")) {
            d.companyName = m.companyName;
        }
        if (m.topLevelCompanyId != null && m.hasOwnProperty("topLevelCompanyId")) {
            if (typeof m.topLevelCompanyId === "number")
                d.topLevelCompanyId = o.longs === String ? String(m.topLevelCompanyId) : m.topLevelCompanyId;
            else
                d.topLevelCompanyId = o.longs === String ? $util.Long.prototype.toString.call(m.topLevelCompanyId) : o.longs === Number ? new $util.LongBits(m.topLevelCompanyId.low >>> 0, m.topLevelCompanyId.high >>> 0).toNumber() : m.topLevelCompanyId;
        }
        return d;
    };

    /**
     * Converts this SsoConfig to JSON.
     * @function toJSON
     * @memberof SsoConfig
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SsoConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return SsoConfig;
})();

$root.SelectedSsoMode = (function() {

    /**
     * Properties of a SelectedSsoMode.
     * @exports ISelectedSsoMode
     * @interface ISelectedSsoMode
     * @property {SelectedSsoMode.SsoMode|null} [mode] SelectedSsoMode mode
     */

    /**
     * Constructs a new SelectedSsoMode.
     * @exports SelectedSsoMode
     * @classdesc Represents a SelectedSsoMode.
     * @implements ISelectedSsoMode
     * @constructor
     * @param {ISelectedSsoMode=} [p] Properties to set
     */
    function SelectedSsoMode(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * SelectedSsoMode mode.
     * @member {SelectedSsoMode.SsoMode} mode
     * @memberof SelectedSsoMode
     * @instance
     */
    SelectedSsoMode.prototype.mode = 0;

    /**
     * Encodes the specified SelectedSsoMode message. Does not implicitly {@link SelectedSsoMode.verify|verify} messages.
     * @function encode
     * @memberof SelectedSsoMode
     * @static
     * @param {SelectedSsoMode} m SelectedSsoMode message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectedSsoMode.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.mode != null && Object.hasOwnProperty.call(m, "mode"))
            w.uint32(8).int32(m.mode);
        return w;
    };

    /**
     * Decodes a SelectedSsoMode message from the specified reader or buffer.
     * @function decode
     * @memberof SelectedSsoMode
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {SelectedSsoMode} SelectedSsoMode
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectedSsoMode.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.SelectedSsoMode();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1:
                m.mode = r.int32();
                break;
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a SelectedSsoMode message.
     * @function verify
     * @memberof SelectedSsoMode
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SelectedSsoMode.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.mode != null && m.hasOwnProperty("mode")) {
            switch (m.mode) {
            default:
                return "mode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        }
        return null;
    };

    /**
     * Creates a SelectedSsoMode message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SelectedSsoMode
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {SelectedSsoMode} SelectedSsoMode
     */
    SelectedSsoMode.fromObject = function fromObject(d) {
        if (d instanceof $root.SelectedSsoMode)
            return d;
        var m = new $root.SelectedSsoMode();
        switch (d.mode) {
        case "LEANPLUM_AND_SSO":
        case 0:
            m.mode = 0;
            break;
        case "SSO_ONLY":
        case 1:
            m.mode = 1;
            break;
        case "SSO_WITH_EXCEPTIONS":
        case 2:
            m.mode = 2;
            break;
        }
        return m;
    };

    /**
     * Creates a plain object from a SelectedSsoMode message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SelectedSsoMode
     * @static
     * @param {SelectedSsoMode} m SelectedSsoMode
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SelectedSsoMode.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.mode = o.enums === String ? "LEANPLUM_AND_SSO" : 0;
        }
        if (m.mode != null && m.hasOwnProperty("mode")) {
            d.mode = o.enums === String ? $root.SelectedSsoMode.SsoMode[m.mode] : m.mode;
        }
        return d;
    };

    /**
     * Converts this SelectedSsoMode to JSON.
     * @function toJSON
     * @memberof SelectedSsoMode
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SelectedSsoMode.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * SsoMode enum.
     * @name SelectedSsoMode.SsoMode
     * @enum {number}
     * @property {number} LEANPLUM_AND_SSO=0 LEANPLUM_AND_SSO value
     * @property {number} SSO_ONLY=1 SSO_ONLY value
     * @property {number} SSO_WITH_EXCEPTIONS=2 SSO_WITH_EXCEPTIONS value
     */
    SelectedSsoMode.SsoMode = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LEANPLUM_AND_SSO"] = 0;
        values[valuesById[1] = "SSO_ONLY"] = 1;
        values[valuesById[2] = "SSO_WITH_EXCEPTIONS"] = 2;
        return values;
    })();

    return SelectedSsoMode;
})();

$root.Companies = (function() {

    /**
     * Properties of a Companies.
     * @exports ICompanies
     * @interface ICompanies
     * @property {Array.<SsoConfig>|null} [companies] Companies companies
     */

    /**
     * Constructs a new Companies.
     * @exports Companies
     * @classdesc Represents a Companies.
     * @implements ICompanies
     * @constructor
     * @param {ICompanies=} [p] Properties to set
     */
    function Companies(p) {
        this.companies = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Companies companies.
     * @member {Array.<SsoConfig>} companies
     * @memberof Companies
     * @instance
     */
    Companies.prototype.companies = $util.emptyArray;

    /**
     * Encodes the specified Companies message. Does not implicitly {@link Companies.verify|verify} messages.
     * @function encode
     * @memberof Companies
     * @static
     * @param {Companies} m Companies message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Companies.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.companies != null && m.companies.length) {
            for (var i = 0; i < m.companies.length; ++i)
                $root.SsoConfig.encode(m.companies[i], w.uint32(10).fork()).ldelim();
        }
        return w;
    };

    /**
     * Decodes a Companies message from the specified reader or buffer.
     * @function decode
     * @memberof Companies
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Companies} Companies
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Companies.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Companies();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1:
                if (!(m.companies && m.companies.length))
                    m.companies = [];
                m.companies.push($root.SsoConfig.decode(r, r.uint32()));
                break;
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a Companies message.
     * @function verify
     * @memberof Companies
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Companies.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.companies != null && m.hasOwnProperty("companies")) {
            if (!Array.isArray(m.companies))
                return "companies: array expected";
            for (var i = 0; i < m.companies.length; ++i) {
                {
                    var e = $root.SsoConfig.verify(m.companies[i]);
                    if (e)
                        return "companies." + e;
                }
            }
        }
        return null;
    };

    /**
     * Creates a Companies message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Companies
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Companies} Companies
     */
    Companies.fromObject = function fromObject(d) {
        if (d instanceof $root.Companies)
            return d;
        var m = new $root.Companies();
        if (d.companies) {
            if (!Array.isArray(d.companies))
                throw TypeError(".Companies.companies: array expected");
            m.companies = [];
            for (var i = 0; i < d.companies.length; ++i) {
                if (typeof d.companies[i] !== "object")
                    throw TypeError(".Companies.companies: object expected");
                m.companies[i] = $root.SsoConfig.fromObject(d.companies[i]);
            }
        }
        return m;
    };

    /**
     * Creates a plain object from a Companies message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Companies
     * @static
     * @param {Companies} m Companies
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Companies.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.arrays || o.defaults) {
            d.companies = [];
        }
        if (m.companies && m.companies.length) {
            d.companies = [];
            for (var j = 0; j < m.companies.length; ++j) {
                d.companies[j] = $root.SsoConfig.toObject(m.companies[j], o);
            }
        }
        return d;
    };

    /**
     * Converts this Companies to JSON.
     * @function toJSON
     * @memberof Companies
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Companies.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Companies;
})();

$root.LoginRequest = (function() {

    /**
     * Properties of a LoginRequest.
     * @exports ILoginRequest
     * @interface ILoginRequest
     * @property {string|null} [email] LoginRequest email
     * @property {string|null} [password] LoginRequest password
     */

    /**
     * Constructs a new LoginRequest.
     * @exports LoginRequest
     * @classdesc Represents a LoginRequest.
     * @implements ILoginRequest
     * @constructor
     * @param {ILoginRequest=} [p] Properties to set
     */
    function LoginRequest(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * LoginRequest email.
     * @member {string} email
     * @memberof LoginRequest
     * @instance
     */
    LoginRequest.prototype.email = "";

    /**
     * LoginRequest password.
     * @member {string} password
     * @memberof LoginRequest
     * @instance
     */
    LoginRequest.prototype.password = "";

    /**
     * Encodes the specified LoginRequest message. Does not implicitly {@link LoginRequest.verify|verify} messages.
     * @function encode
     * @memberof LoginRequest
     * @static
     * @param {LoginRequest} m LoginRequest message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginRequest.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.email != null && Object.hasOwnProperty.call(m, "email"))
            w.uint32(10).string(m.email);
        if (m.password != null && Object.hasOwnProperty.call(m, "password"))
            w.uint32(18).string(m.password);
        return w;
    };

    /**
     * Decodes a LoginRequest message from the specified reader or buffer.
     * @function decode
     * @memberof LoginRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {LoginRequest} LoginRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginRequest.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.LoginRequest();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1:
                m.email = r.string();
                break;
            case 2:
                m.password = r.string();
                break;
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a LoginRequest message.
     * @function verify
     * @memberof LoginRequest
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginRequest.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.email != null && m.hasOwnProperty("email")) {
            if (!$util.isString(m.email))
                return "email: string expected";
        }
        if (m.password != null && m.hasOwnProperty("password")) {
            if (!$util.isString(m.password))
                return "password: string expected";
        }
        return null;
    };

    /**
     * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LoginRequest
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {LoginRequest} LoginRequest
     */
    LoginRequest.fromObject = function fromObject(d) {
        if (d instanceof $root.LoginRequest)
            return d;
        var m = new $root.LoginRequest();
        if (d.email != null) {
            m.email = String(d.email);
        }
        if (d.password != null) {
            m.password = String(d.password);
        }
        return m;
    };

    /**
     * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LoginRequest
     * @static
     * @param {LoginRequest} m LoginRequest
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginRequest.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.email = "";
            d.password = "";
        }
        if (m.email != null && m.hasOwnProperty("email")) {
            d.email = m.email;
        }
        if (m.password != null && m.hasOwnProperty("password")) {
            d.password = m.password;
        }
        return d;
    };

    /**
     * Converts this LoginRequest to JSON.
     * @function toJSON
     * @memberof LoginRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return LoginRequest;
})();

$root.LoginResponse = (function() {

    /**
     * Properties of a LoginResponse.
     * @exports ILoginResponse
     * @interface ILoginResponse
     * @property {string|null} [redirectTo] LoginResponse redirectTo
     * @property {boolean|null} [passwordRequired] LoginResponse passwordRequired
     * @property {string|null} [error] LoginResponse error
     */

    /**
     * Constructs a new LoginResponse.
     * @exports LoginResponse
     * @classdesc Represents a LoginResponse.
     * @implements ILoginResponse
     * @constructor
     * @param {ILoginResponse=} [p] Properties to set
     */
    function LoginResponse(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * LoginResponse redirectTo.
     * @member {string} redirectTo
     * @memberof LoginResponse
     * @instance
     */
    LoginResponse.prototype.redirectTo = "";

    /**
     * LoginResponse passwordRequired.
     * @member {boolean} passwordRequired
     * @memberof LoginResponse
     * @instance
     */
    LoginResponse.prototype.passwordRequired = false;

    /**
     * LoginResponse error.
     * @member {string} error
     * @memberof LoginResponse
     * @instance
     */
    LoginResponse.prototype.error = "";

    /**
     * Encodes the specified LoginResponse message. Does not implicitly {@link LoginResponse.verify|verify} messages.
     * @function encode
     * @memberof LoginResponse
     * @static
     * @param {LoginResponse} m LoginResponse message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginResponse.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.redirectTo != null && Object.hasOwnProperty.call(m, "redirectTo"))
            w.uint32(10).string(m.redirectTo);
        if (m.passwordRequired != null && Object.hasOwnProperty.call(m, "passwordRequired"))
            w.uint32(16).bool(m.passwordRequired);
        if (m.error != null && Object.hasOwnProperty.call(m, "error"))
            w.uint32(26).string(m.error);
        return w;
    };

    /**
     * Decodes a LoginResponse message from the specified reader or buffer.
     * @function decode
     * @memberof LoginResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {LoginResponse} LoginResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginResponse.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.LoginResponse();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1:
                m.redirectTo = r.string();
                break;
            case 2:
                m.passwordRequired = r.bool();
                break;
            case 3:
                m.error = r.string();
                break;
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a LoginResponse message.
     * @function verify
     * @memberof LoginResponse
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginResponse.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.redirectTo != null && m.hasOwnProperty("redirectTo")) {
            if (!$util.isString(m.redirectTo))
                return "redirectTo: string expected";
        }
        if (m.passwordRequired != null && m.hasOwnProperty("passwordRequired")) {
            if (typeof m.passwordRequired !== "boolean")
                return "passwordRequired: boolean expected";
        }
        if (m.error != null && m.hasOwnProperty("error")) {
            if (!$util.isString(m.error))
                return "error: string expected";
        }
        return null;
    };

    /**
     * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LoginResponse
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {LoginResponse} LoginResponse
     */
    LoginResponse.fromObject = function fromObject(d) {
        if (d instanceof $root.LoginResponse)
            return d;
        var m = new $root.LoginResponse();
        if (d.redirectTo != null) {
            m.redirectTo = String(d.redirectTo);
        }
        if (d.passwordRequired != null) {
            m.passwordRequired = Boolean(d.passwordRequired);
        }
        if (d.error != null) {
            m.error = String(d.error);
        }
        return m;
    };

    /**
     * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LoginResponse
     * @static
     * @param {LoginResponse} m LoginResponse
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginResponse.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.redirectTo = "";
            d.passwordRequired = false;
            d.error = "";
        }
        if (m.redirectTo != null && m.hasOwnProperty("redirectTo")) {
            d.redirectTo = m.redirectTo;
        }
        if (m.passwordRequired != null && m.hasOwnProperty("passwordRequired")) {
            d.passwordRequired = m.passwordRequired;
        }
        if (m.error != null && m.hasOwnProperty("error")) {
            d.error = m.error;
        }
        return d;
    };

    /**
     * Converts this LoginResponse to JSON.
     * @function toJSON
     * @memberof LoginResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return LoginResponse;
})();

$root.AssignRequest = (function() {

    /**
     * Properties of an AssignRequest.
     * @exports IAssignRequest
     * @interface IAssignRequest
     * @property {string|null} [idpId] AssignRequest idpId
     */

    /**
     * Constructs a new AssignRequest.
     * @exports AssignRequest
     * @classdesc Represents an AssignRequest.
     * @implements IAssignRequest
     * @constructor
     * @param {IAssignRequest=} [p] Properties to set
     */
    function AssignRequest(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * AssignRequest idpId.
     * @member {string} idpId
     * @memberof AssignRequest
     * @instance
     */
    AssignRequest.prototype.idpId = "";

    /**
     * Encodes the specified AssignRequest message. Does not implicitly {@link AssignRequest.verify|verify} messages.
     * @function encode
     * @memberof AssignRequest
     * @static
     * @param {AssignRequest} m AssignRequest message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssignRequest.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.idpId != null && Object.hasOwnProperty.call(m, "idpId"))
            w.uint32(10).string(m.idpId);
        return w;
    };

    /**
     * Decodes an AssignRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AssignRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {AssignRequest} AssignRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssignRequest.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.AssignRequest();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1:
                m.idpId = r.string();
                break;
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies an AssignRequest message.
     * @function verify
     * @memberof AssignRequest
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssignRequest.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.idpId != null && m.hasOwnProperty("idpId")) {
            if (!$util.isString(m.idpId))
                return "idpId: string expected";
        }
        return null;
    };

    /**
     * Creates an AssignRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AssignRequest
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {AssignRequest} AssignRequest
     */
    AssignRequest.fromObject = function fromObject(d) {
        if (d instanceof $root.AssignRequest)
            return d;
        var m = new $root.AssignRequest();
        if (d.idpId != null) {
            m.idpId = String(d.idpId);
        }
        return m;
    };

    /**
     * Creates a plain object from an AssignRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AssignRequest
     * @static
     * @param {AssignRequest} m AssignRequest
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssignRequest.toObject = function toObject(m, o) {
        if (!o)
            o = {};
        var d = {};
        if (o.defaults) {
            d.idpId = "";
        }
        if (m.idpId != null && m.hasOwnProperty("idpId")) {
            d.idpId = m.idpId;
        }
        return d;
    };

    /**
     * Converts this AssignRequest to JSON.
     * @function toJSON
     * @memberof AssignRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssignRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return AssignRequest;
})();

$root.Empty = (function() {

    /**
     * Properties of an Empty.
     * @exports IEmpty
     * @interface IEmpty
     */

    /**
     * Constructs a new Empty.
     * @exports Empty
     * @classdesc Represents an Empty.
     * @implements IEmpty
     * @constructor
     * @param {IEmpty=} [p] Properties to set
     */
    function Empty(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * Encodes the specified Empty message. Does not implicitly {@link Empty.verify|verify} messages.
     * @function encode
     * @memberof Empty
     * @static
     * @param {Empty} m Empty message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Empty.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        return w;
    };

    /**
     * Decodes an Empty message from the specified reader or buffer.
     * @function decode
     * @memberof Empty
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Empty} Empty
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Empty.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.Empty();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies an Empty message.
     * @function verify
     * @memberof Empty
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Empty.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        return null;
    };

    /**
     * Creates an Empty message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Empty
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Empty} Empty
     */
    Empty.fromObject = function fromObject(d) {
        if (d instanceof $root.Empty)
            return d;
        return new $root.Empty();
    };

    /**
     * Creates a plain object from an Empty message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Empty
     * @static
     * @param {Empty} m Empty
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Empty.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Empty to JSON.
     * @function toJSON
     * @memberof Empty
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Empty.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Empty;
})();

module.exports = $root;
